<template>
  <!-- Компания и новости -->
  <div class="chapter">
    <div id="titlewrapper"></div>
    <div id="content-wrapper">
      <div id="about">
        <h1 id="naming">{{ $t("message.CNTitle") }}</h1>
        <div id="textinnews">
          {{ $t("message.CNText1") }}
          <p></p>
          {{ $t("message.CNText2") }}
          <p></p>
          {{ $t("message.CNText3") }}
          <p></p>
          {{ $t("message.CNText4") }}
          <p></p>
          {{ $t("message.CNText5") }}
        </div>
      </div>
      <div id="news">
        <div id="news-wrapper">
          <h1 id="naming">{{ $t("message.CNNews") }}</h1>
          <v-btn
            small
            id="btnwidewhite"
            @click="$router.push('/informs/news')"
            >{{ $t("message.CNNewsBtn") }}</v-btn
          >
        </div>
        <div id="news-cards">
          <!-- <router-link to="informs/newspages/290719">
            <v-card max-width="530" min-height="100" id="news-card">
              <div id="news-card-container">
                <img
                  :src="getImageUrlbyName('News1')"
                  href=""
                  id="news-card-pic"
                />
                <div id="news-card-content">
                  <div id="news-carddate">{{ $t("message.CNDate1") }}</div>
                  <div id="news-cardtitle">
                    {{ $t("message.NewsCard1") }}
                  </div>
                </div>
              </div>
            </v-card>
          </router-link>
          <router-link to="informs/newspages/261018">
            <v-card max-width="530" min-height="100" id="news-card">
              <div id="news-card-container">
                <img
                  :src="getImageUrlbyName('News2')"
                  href=""
                  id="news-card-pic"
                />
                <div id="news-card-content">
                  <div id="news-carddate">{{ $t("message.CNDate2") }}</div>
                  <div id="news-cardtitle">
                    {{ $t("message.NewsCard2") }}
                  </div>
                </div>
              </div>
            </v-card>
          </router-link>
          <router-link to="informs/newspages/051018">
            <v-card max-width="530" min-height="100" id="news-card">
              <div id="news-card-container">
                <img
                  :src="getImageUrlbyName('News3')"
                  href=""
                  id="news-card-pic"
                />
                <div id="news-card-content">
                  <div id="news-carddate">{{ $t("message.CNDate3") }}</div>
                  <div id="news-cardtitle">
                    {{ $t("message.NewsCard3") }}
                  </div>
                </div>
              </div>
            </v-card>
          </router-link>
          <router-link to="informs/newspages/200618">
            <v-card max-width="530" min-height="100" id="news-card">
              <div id="news-card-container">
                <img
                  :src="getImageUrlbyName('News4')"
                  href=""
                  id="news-card-pic"
                />
                <div id="news-card-content">
                  <div id="news-carddate">{{ $t("message.CNDate4") }}</div>
                  <div id="news-cardtitle">
                    {{ $t("message.NewsCard4") }}
                  </div>
                </div>
              </div>
            </v-card>
          </router-link>
          <router-link to="informs/newspages/010318">
            <v-card max-width="530" min-height="100" id="news-card">
              <div id="news-card-container">
                <img
                  :src="getImageUrlbyName('News5')"
                  href=""
                  id="news-card-pic"
                />
                <div id="news-card-content">
                  <div id="news-carddate">{{ $t("message.CNDate5") }}</div>
                  <div id="news-cardtitle">
                    {{ $t("message.NewsCard5") }}
                  </div>
                </div>
              </div>
            </v-card>
          </router-link>
          <router-link to="informs/newspages/200516">
            <v-card max-width="530" min-height="100" id="news-card">
              <div id="news-card-container">
                <img
                  :src="getImageUrlbyName('News6')"
                  href=""
                  id="news-card-pic"
                />
                <div id="news-card-content">
                  <div id="news-carddate">{{ $t("message.CNDate6") }}</div>
                  <div id="news-cardtitle">
                    {{ $t("message.NewsCard6") }}
                  </div>
                </div>
              </div>
            </v-card>
          </router-link> -->
          <!--  -->
          <!-- <router-link to="informs/news"> -->
          <v-card v-scroll.self="onScroll" class="overflow-y-auto" max-height = "600">
          <v-col v-for="item in items" :key="item.id" :cols="item.id" >
            <v-card
              max-width="530"
              min-height="100"
              id="news-card"
              v-on:click="saveCurrentItem(item)"
              @click="$router.push('/informs/newspages/anynews')"
            >
              <div id="news-card-container">
                <img
                  :src="getImageUrl(item.image)"
                  href=""
                  id="news-card-pic"
                />
                <div id="news-card-content">
                  <div id="news-carddate">{{ getItemDate(item) }}</div>
                  <div id="news-cardtitle">
                    {{ getItemName(item) }}
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
          </v-card>
          <!-- </router-link> -->
          <!--  -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [],
  }),
  async mounted() {
    await this.getNewsblock();
  },
  methods: {
    async getNewsblock() {
      await this.$store.dispatch("getAllNewsblocks");
      this.items = await this.$store.getters.getNewsblocks;
      this.items = this.items.sort(
        (b, a) => new Date(a.date) - new Date(b.date)
      );
      // console.log(this.items);
    },
    getImageUrl(item) {
      return "data:image/jpeg;base64," + item;
    },
    getItemName(item) {
      if (this.$i18n.locale === "ru") {
        return item.name2;
      } else if (this.$i18n.locale === "gb") {
        return item.name1;
      } else if (this.$i18n.locale === "de") {
        return item.name3;
      }
    },
    getItemDate(item) {
      return item.date.slice(0, 10);
    },
    saveCurrentItem(item) {
      localStorage.itemid = item.id;
      localStorage.itemimage= item.image;
      localStorage.itemname1 = item.name1;
      localStorage.itemname2 = item.name2;
      localStorage.itemname3 = item.name3;
      localStorage.itemdate = item.date.slice(0, 10);
      localStorage.itemlongtext1 = item.longtext1;
      localStorage.itemlongtext2 = item.longtext2;
      localStorage.itemlongtext3 = item.longtext3;
      console.log(localStorage.itemname1);
      return null;
    },
    onScroll () {
    },
    // getItemShorttext(item) {
    //   if (this.$i18n.locale === "ru") {
    //     return item.shorttext2;
    //   } else if (this.$i18n.locale === "gb") {
    //     return item.shorttext1;
    //   } else if (this.$i18n.locale === "de") {
    //     return item.shorttext3;
    //   }
    // },
    // getItemLongtext(item) {
    //   if (this.$i18n.locale === "ru") {
    //     return item.shorttext2;
    //   } else if (this.$i18n.locale === "gb") {
    //     return item.shorttext1;
    //   } else if (this.$i18n.locale === "de") {
    //     return item.shorttext3;
    //   }
    // },
  },
};
</script>

<style>
/* Компания и новости */
#news-wrapper {
  display: flex;
  justify-content: space-between;
}
#textinnews {
  font-size: 14px;
}
#content-wrapper {
  display: flex;
  padding-bottom: 40px;
}
#about {
  width: 45%;
}
#news {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 8px;
}
#news-card {
  display: flex;
  align-items: center;
}
#news-card-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
#news-card-content {
  width: 75%;
}
#news-card-pic {
  width: 20%;
}
#news-carddate {
  font-size: 11px;
  border-left: 1px solid #c4c4c4;
  color: #747474;
  padding-left: 8px;
}
#news-cardtitle {
  font-size: 14px;
  padding-top: 4px;
}
@media (max-width: 480px) {
  #content-wrapper {
    flex-direction: column;
  }
  #about {
    width: 100%;
  }
  #news {
    padding-top: 20px;
    width: 100%;
  }
}
@media (max-width: 1500px) {
  #content-wrapper {
    flex-direction: column;
  }
  #about {
    width: 100%;
  }
  #news {
    padding-top: 20px;
    width: 100%;
  }
}
</style>