<template>
  <footer id="footer">
    <div id="footer-chapter">
      <div id="top-footer">
        <div id="promo">
          <img id="send-mail" src="../assets/images/send.jpg" />
          <div>{{ $t("message.FooterSubText") }}</div>
        </div>
        <div id="input">
          <v-dialog v-model="dialog" width="500" id="fb-bg">
            <template v-slot:activator="{ on }">
              <v-card id="fot-card">
                <v-btn v-on="on" id="btnsub">{{
                  $t("message.FooterSubBtn")
                }}</v-btn>
              </v-card>
            </template>
            <v-card min-height="500">
              <div id="feedback-marg">
                <Feedback />
              </div>
            </v-card>
          </v-dialog>
        </div>
        <div id="society">
          <v-btn
            class="mx-2"
            fab
            small
            id="iconbutton"
            href="https://vk.com/its163"
          >
            <img src="../assets/images/vk.png" width="25px" height="25px" />
          </v-btn>

          <v-btn
            class="mx-2"
            fab
            small
            id="iconbutton"
            href="https://www.youtube.com/channel/UCbEdPtBs8b5ily6pl2VzrOg"
          >
            <img
              src="../assets/images/youtube.png"
              width="32px"
              height="32px"
            />
          </v-btn>
        </div>
      </div>
    </div>
    <div id="bottom-footer">
      <div id="copyright">{{ $t("message.FooterCopyright") }}</div>

      <div id="phone-c">
        <img src="../assets/images/phonef.jpg" height="15" width="15" />
        <div id="phone-num">+7 (846) 219-26-28</div>
      </div>
      <div id="mail-c">
        <img src="../assets/images/mailf.jpg" />
        <div id="mail">info@its.ooo</div>
      </div>
      <!-- <div id="footer-contacts"></div> -->
    </div>
  </footer>
</template>

<script>
import Feedback from "../components/Feedback";
export default {
  name: "Footer",
  components: {
    Feedback,
  },
};
</script>

<style>
/* Футер */
#footer {
  background: none repeat scroll 0 0 #2c364a;
  box-shadow: 0 70px 50px -20px #213040 inset;
  clear: both;
  color: #969ba5;
  font-size: 12px;
  line-height: 18px;
  min-height: 171px;
  padding-bottom: 1400px;
  position: relative;
  z-index: 0;
  font-family: Ubuntu, Arial, sans-serif;
  animation: mymove 3s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}
@keyframes mymove {
  100% {
    padding-bottom: 0px;
  }
}

#footer-chapter {
  height: 100%;
  width: 100%;
  padding-left: 25%;
  padding-right: 25%;
}
#footer-chapter::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  margin-top: 10px;
  background-color: #505050;
}

#promo {
  display: flex;
  align-items: center;
  width: 33%;
  color: #ffffff;
  font-size: 14px;
}

#input {
  display: flex;
  align-items: center;
  width: 33%;
  padding-left: 45px;
}
#btnsub {
  background-color: #00569c;
  color: #e3e7ec;
  max-height: 32px;
  margin-bottom: 6px;
}
#society {
  display: flex;
  align-items: center;
  width: 33%;
  padding-left: 25px;
}
#fot-card {
  background-color: #2c364a;
  max-height: 32px;
}
#top-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 20px;
}
#iconbutton {
  height: 30px;
  width: 30px;
}
#bottom-footer {
  display: flex;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  padding-left: 25%;
  padding-right: 25%;
  padding-top: 20px;
}
#copyright {
  display: flex;
  width: 33%;
  font-size: 10px;
}
#footer-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 60%;
}
#footer-links a {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 12px;
  width: 30%;
  padding-bottom: 15px;
}
#footer-links a:hover {
  text-decoration: none;
}
#footer-contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  padding-left: 25px;
}
#phone-c {
  display: flex;
  width: 33%;
}
#phone-num {
  font-size: 14px;
  color: #ffffff;
  padding-left: 7px;
}
#mail-c {
  display: flex;
  width: 33%;
}
#mail {
  padding-left: 7px;
}

@media (max-width: 920px) {
  #top-footer {
    flex-direction: column;
  }
  #footer-links {
    flex-direction: column;
  }
  #send-mail {
    display: none;
  }
  #promo {
    width: 100%;
    justify-content: center;
    padding-top: 10px;
  }
  #footer-chapter {
    padding-left: 5%;
    padding-right: 5%;
  }
  #input {
    width: 100%;
    justify-content: center;
  }
  #society {
    width: 100%;
    justify-content: center;
  }
  #bottom-footer {
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
  }
  #copyright {
    width: 100%;
    justify-content: center;
    padding-bottom: 10px;
  }
  #phone-c {
    width: 100%;
    justify-content: center;
    padding-bottom: 10px;
  }
  #mail-c {
    width: 100%;
    justify-content: center;
    padding-bottom: 10px;
  }
  #footer-links {
    display: none;
  }

  #footer-contacts {
    padding-top: 10px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 1650px) {
  #top-footer {
    flex-direction: column;
  }
  #footer-links {
    flex-direction: column;
  }
  #send-mail {
    display: none;
  }
  #promo {
    width: 100%;
    justify-content: center;
    padding-top: 20px;
  }
  #footer-chapter {
    padding-left: 5%;
    padding-right: 5%;
  }
  #input {
    width: 100%;
    justify-content: center;
    padding-left: 0px;
    padding-top: 20px;
  }
  #society {
    width: 100%;
    justify-content: center;
    padding-top: 20px;
  }
}
</style>