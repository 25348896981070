export default {
    actions: {
        async getAllWorkblocks(ctx) {
            const axios = require("axios").default;
            let configs = null;
            let getConfig = axios({
                url: "../../config.json",
            }).then((respose) => {
                configs = respose.data;
                return configs;
            });

            const config = await getConfig;
            let url = "";

            for (let i = 0; i < config.length; i++) {
                if (config[i].name === "api") {
                    url = config[i].ip + "/GetAllWorkblocks";
                }
            }
            const getWorkblocksAxios = async (url, ctx) => {
                let count = 0;
                await axios.get(url)
                    .then((response) => {

                        //     console.log(response);
                        //     console.log(ctx);
                        if (response.status == "200") {
                            // console.log(response);
                            ctx.commit("updateAllWorkblocks", response.data);
                            // ctx.commit("loadp", response.data);
                        }
                    })
                    .catch(async (error) => {
                        console.log(error);
                        if (count > 3) {
                            await getWorkblocksAxios(url);
                        }
                        count++;
                    });
            }

            await getWorkblocksAxios(url, ctx);
        }
    },
    mutations: {
        updateAllWorkblocks(state, workblocks) {
            state.workblocks = workblocks;
        },        

    },
    state: {
        workblocks: [],
        
    },
    getters: {
        getWorkblocks(state) {
            return state.workblocks;
        },
    }

}