
export default [
    {
        path: '/company/aboutcompany',
        name: 'AboutCompany',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Company/AboutCompany')
      },
      {
        path: '/company/history',
        name: 'History',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Company/History')
      },
      {
        path: '/company/licenses',
        name: 'Licenses',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Company/Licenses')
      },
      {
        path: '/company/sertif',
        name: 'Sertif',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Company/Sertif')
      },
      {
        path: '/company/partn',
        name: 'Partn',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Company/Partn')
      },
      {
        path: '/company/clients',
        name: 'Clients',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Company/Clients')
      },
      {
        path: '/company/aboutus',
        name: 'AboutUs',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Company/AboutUs')
      },
      {
        path: '/company/workers',
        name: 'Workers',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Company/Workers')
      },
      {
        path: '/company/requ',
        name: 'Requ',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Company/Requ')
      },
      {
        path: '/company/workpages/director',
        name: 'Director',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Company/WorkPages/Director')
      },
      {
        path: '/company/workpages/findir',
        name: 'Findir',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Company/WorkPages/Findir')
      },
      {
        path: '/company/workpages/goretski',
        name: 'Goretski',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Company/WorkPages/Goretski')
      },
      {
        path: '/company/workpages/shapilov',
        name: 'Shapilov',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Company/WorkPages/Shapilov')
      },
      {
        path: '/company/workpages/suhorukhov',
        name: 'Suhorukhov',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Company/WorkPages/Suhorukhov')
      },
      {
        path: '/company/workpages/anyworker',
        name: 'Anyworker',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Company/WorkPages/Anyworker')
      },
    
]