
export default {
    actions: {
        async getAllPictureblocks(ctx) {
            const axios = require("axios").default;
            let configs = null;
            let getConfig = axios({
                url: "../../config.json",
            }).then((respose) => {
                configs = respose.data;
                return configs;
            });

            const config = await getConfig;
            let url = "";

            for (let i = 0; i < config.length; i++) {
                if (config[i].name === "api") {
                    url = config[i].ip + "/GetAllPictureblocks";
                }
            }
            const getPictureblocksAxios = async (url, ctx) => {
                let count = 0;
                await axios.get(url)
                    .then((response) => {

                        //     console.log(response);
                        //     console.log(ctx);
                        if (response.status == "200") {
                            // console.log(response);
                            ctx.commit("updateAllPictureblocks", response.data);
                            ctx.commit("loadp", response.data);
                        }
                    })
                    .catch(async (error) => {
                        console.log(error);
                        if (count > 3) {
                            await getPictureblocksAxios(url);
                        }
                        count++;
                    });
            }

            await getPictureblocksAxios(url, ctx);
        }
    },
    mutations: {
        updateAllPictureblocks(state, pictureblocks) {
            state.pictureblocks = pictureblocks;
        },
        loadp(state, pictureblocks) {
            if (pictureblocks !== undefined) {
                state.picLoading = true
            }
            else if (pictureblocks !== undefined) {
                state.picLoading = true
            }
        },

    },
    state: {
        pictureblocks: [],
        picLoading: false,
    },
    getters: {
        getPictureblocks(state) {
            return state.pictureblocks;
        },
        getImageUrl(state) {
            return state.pictureblocks.picture;
        }
    }

}