export default {
    actions: {
    
    },
    mutations: {
        reserve(state){
            setTimeout(() => (state.loading = false), 2000)
        },
    },
    state: {
        loading: true,
        selection: 1,
    },
    getters: {
               
    }
    
}