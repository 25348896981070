<template>
  <!-- Консультации (фон) -->
  <div id="consult">
    <div id="back-wrapper">
      <div id="consult-wrapper">
        <div id="consult-text">
          <h1 id="naming-no-line">{{ $t("message.ConsultTitle") }}</h1>
          <div id="consulttext">
            {{ $t("message.ConsultText") }}
          </div>
        </div>
        <v-dialog v-model="dialog" width="500" id="fb-bg">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" id="btnblue"
              >{{ $t("message.ConsultBtn") }}
            </v-btn>
          </template>
          <v-card min-height="500">
            <div id="feedback-marg">
              <Feedback />
            </div>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Feedback from "../components/Feedback";
export default {
  components: {
    Feedback,
  },
  data() {
    return {
      dialog: false,
    }
  },
};
</script>

<style>
/* Консультация */
#consult {
  height: 100%;
  width: 100%;
  padding-left: 25%;
  padding-right: 25%;
  padding-top: 40px;
  padding-bottom: 40px;
}
#naming-no-line {
  font-size: 24px;

  display: inline;
}
#consult-wrapper {
  display: flex;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
}

#consulttext {
  font-size: 13px;
}

/* Для блока консультаций */
#back-wrapper {
  background: url("../assets/images/background.png");
  border-left: 2px solid #00569c;
}
@media (max-width: 480px) {
  #consult {
    padding-left: 5%;
    padding-right: 5%;
  }
  #consult-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 1070px) {
  #consult-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>