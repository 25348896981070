
export default [
    {
        path: '/informs/article',
        name: 'Article',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Informs/Article')
      },
      {
        path: '/informs/ask',
        name: 'Ask',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Informs/Ask')
      },
      {
        path: '/informs/present',
        name: 'Present',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Informs/Present')
      },
      {
        path: '/informs/newspages/290719',
        name: '290719',
        meta: { layout: 'empty' },
        component: () => import('../../views/Navigations/Informs/Newspages/290719')
      },
      {
        path: '/informs/newspages/261018',
        name: '261018',
        meta: { layout: 'empty' },
        component: () => import('../../views/Navigations/Informs/Newspages/261018')
      },
      {
        path: '/informs/newspages/200618',
        name: '200618',
        meta: { layout: 'empty' },
        component: () => import('../../views/Navigations/Informs/Newspages/200618')
      },
      {
        path: '/informs/newspages/200516',
        name: '200516',
        meta: { layout: 'empty' },
        component: () => import('../../views/Navigations/Informs/Newspages/200516')
      },
      {
        path: '/informs/newspages/190416',
        name: '190416',
        meta: { layout: 'empty' },
        component: () => import('../../views/Navigations/Informs/Newspages/190416')
      },
      {
        path: '/informs/newspages/051018',
        name: '051018',
        meta: { layout: 'empty' },
        component: () => import('../../views/Navigations/Informs/Newspages/051018')
      },
      {
        path: '/informs/newspages/010318',
        name: '010318',
        meta: { layout: 'empty' },
        component: () => import('../../views/Navigations/Informs/Newspages/010318')
      },
      {
        path: '/informs/newspages/anynews',
        name: 'Anynews',
        meta: { layout: 'empty' },
        component: () => import('../../views/Navigations/Informs/Newspages/Anynews')
      },
]