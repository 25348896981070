var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"nbarcard"}},[_vm._v(" "+_vm._s(_vm.scrollingn())+" "),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"navmenubtn"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("message.Company"))+" ")])]}}])},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){return _vm.$router.push({ path: item.route })}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(item.title)))])],1)}),1)],1)],1),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"navmenubtn"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("message.Products"))+" ")])]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","href":"https://smartruck.ru/"}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("message.SmarTruckTitle")))])],1),_c('v-list-item',{attrs:{"link":"","href":"https://smarteams.ru/"}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("message.SmarTeamsTitle")))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$router.push({ path: '/products/sprod' })}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("message.SmartProductionTitle")))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$router.push({ path: '/products/benztruck' })}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("message.NavbarBenztruck")))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$router.push({ path: '/products/delivery' })}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("message.NavbarDelivery")))])],1),_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("message.AdditionalServices")))])],1)]},proxy:true}])},[_c('v-list',_vm._l((_vm.advservices),function(advs,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){return _vm.$router.push({ path: advs.route })}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(advs.title)))])],1)}),1)],1)],1)],1)],1),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"navmenubtn"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("message.ProjectsTitle"))+" ")])]}}])},[_c('v-list',_vm._l((_vm.projects),function(project,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){return _vm.$router.push({ path: project.route })}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(project.title)))])],1)}),1)],1)],1),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"navmenubtn"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("message.InformsTitle"))+" ")])]}}])},[_c('v-list',_vm._l((_vm.informs),function(info,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){return _vm.$router.push({ path: info.route })}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(info.title)))])],1)}),1)],1)],1),_c('v-btn',_vm._g(_vm._b({attrs:{"id":"navmenubtn"},on:{"click":function($event){return _vm.$router.push('/contacts')}}},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(" "+_vm._s(_vm.$t("message.ContactsTitle"))+" ")]),_c('div',{staticClass:"text-center"},[_c('v-select',{attrs:{"id":"selected","items":_vm.langs,"flags":_vm.flags,"solo":"","hide-details":"","single-line":"","label":"Change language:","color":"primary"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('country-flag',{attrs:{"country":item,"size":"normal"}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('country-flag',{attrs:{"country":item,"size":"normal"}})]}}]),model:{value:(_vm.locale),callback:function ($$v) {_vm.locale=$$v},expression:"locale"}})],1),_c('router-link',{attrs:{"id":"navbar-link","to":"/Login"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","small":"","id":"loginbtn","dark":""},domProps:{"innerHTML":_vm._s(_vm.$t('message.Login'))},on:{"click":function($event){return _vm.$router.push({})}}},'v-btn',_vm.attrs,false),_vm.on))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }