<template >
  <v-card id="nbarcard">
    {{ scrollingn() }}
    <!--  -->
    <div class="text-center">
      <v-menu open-on-hover bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" id="navmenubtn">
            {{ $t("message.Company") }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            link
            @click="$router.push({ path: item.route })"
          >
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <!--  -->
    <div class="text-center">
      <v-menu open-on-hover bottom offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" id="navmenubtn">
            {{ $t("message.Products") }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item link href="https://smartruck.ru/">
            <!-- @click="$router.push({ path: '/products/struck' })" -->
            <v-list-item-title>{{
              $t("message.SmarTruckTitle")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item link href="https://smarteams.ru/">
            <!-- @click="$router.push({ path: '/products/steams' })" -->
            <v-list-item-title>{{
              $t("message.SmarTeamsTitle")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="$router.push({ path: '/products/sprod' })">
            <!-- @click="$router.push({ path: '/products/sprod' })" -->
            <v-list-item-title>{{
              $t("message.SmartProductionTitle")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="$router.push({ path: '/products/benztruck' })"
          >
            <v-list-item-title>{{
              $t("message.NavbarBenztruck")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="$router.push({ path: '/products/delivery' })"
          >
            <v-list-item-title>{{
              $t("message.NavbarDelivery")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("message.AdditionalServices")
                }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list>
              <v-list-item
                v-for="(advs, index) in advservices"
                :key="index"
                link
                @click="$router.push({ path: advs.route })"
              >
                <v-list-item-title> {{ $t(advs.title) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-list-group>
        </v-list>
      </v-menu>
    </div>
    <!--  -->
    <div class="text-center">
      <v-menu open-on-hover bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" id="navmenubtn">
            {{ $t("message.ProjectsTitle") }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(project, index) in projects"
            :key="index"
            link
            @click="$router.push({ path: project.route })"
          >
            <v-list-item-title>{{ $t(project.title) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="text-center">
      <v-menu open-on-hover bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" id="navmenubtn">
            {{ $t("message.InformsTitle") }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(info, index) in informs"
            :key="index"
            link
            @click="$router.push({ path: info.route })"
          >
            <v-list-item-title>{{ $t(info.title) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <v-btn
      v-bind="attrs"
      v-on="on"
      @click="$router.push('/contacts')"
      id="navmenubtn"
    >
      {{ $t("message.ContactsTitle") }}
    </v-btn>
    <div class="text-center">
      <v-select
        id="selected"
        :items="langs"
        :flags="flags"
        solo
        v-model="locale"
        hide-details
        single-line
        label="Change language:"
        color="primary"
      >
        <template v-slot:selection="{ item }">
          <country-flag :country="item" size="normal" />
        </template>
        <template v-slot:item="{ item }">
          <country-flag :country="item" size="normal" />
        </template>
      </v-select>
    </div>
    <!--  -->
    <!-- <div class="text-center">
      <v-container>
        <v-btn color="primary" dark @click="toggleSelect = !toggleSelect" >
          Change language:
        </v-btn>
        <v-select
        v-if="toggleSelect"
        :menu-props="{value: toggleSelect}"
        :items="langs"
        v-model="locale"
        hide-details
        single-line       
      >
        <template v-slot:selection="{ item }">
          <country-flag :country="item" size="normal" />
        </template>
        <template v-slot:item="{ item }">
          <country-flag :country="item" size="normal" />
        </template>
      </v-select>
      </v-container>
    </div> -->
    <router-link id="navbar-link" to="/Login"
      ><v-btn
        color="secondary"
        small
        id="loginbtn"
        dark
        v-bind="attrs"
        v-on="on"
        v-html="$t('message.Login')"
        @click="$router.push({})"
      >
      </v-btn
    ></router-link>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    items: [
      { title: "message.NavbarAboutCompany", route: "/company/aboutcompany" },
      // { title: "message.NavbarHistory", route: "/company/history" },
      { title: "message.NavbarLicenses", route: "/company/licenses" },
      { title: "message.NavbarSertif", route: "/company/sertif" },
      { title: "message.NavbarPartn", route: "/company/partn" },
      { title: "message.NavbarClients", route: "/company/clients" },
      // { title: "message.NavbarAboutUs", route: "/company/aboutus" },
      { title: "message.NavbarWorkers", route: "/company/workers" },
      { title: "message.NavbarRequ", route: "/company/requ" },
    ],
    advservices: [
      {
        title: "message.NavbarIntegration",
        route: "/products/integration",
      },
      {
        title: "message.NavbarRefact",
        route: "/products/refact",
      },
      {
        title: "message.NavbarAudit",
        route: "/products/audit",
      },
      // {
      //   title: "message.NavbarDelivery",
      //   route: "/products/delivery",
      // },
      // { title: "message.NavbarBenztruck", route: "/products/benztruck" },
      { title: "message.NavbarMerch", route: "/products/merch" },
      { title: "message.NavbarTrash", route: "/products/trash" },
    ],
    projects: [
      { title: "message.NavbarCocacola", route: "/projects/cocacola" },
      { title: "message.NavbarVPT", route: "/projects/VPT" },
      // {
      //   title: "message.NavbarElectrum",
      //   route: "/projects/electrum",
      // },
      { title: "message.NavbarSVGK", route: "/projects/svgk" },
      { title: "message.NavbarTrasko", route: "/projects/trasko" },
      {
        title: "message.NavbarResourstrans",
        route: "/projects/resourstrans",
      },
    ],
    informs: [
      { title: "message.NavbarNews", route: "/informs/news" },
      { title: "message.NavbarArticle", route: "/informs/article" },
      { title: "message.NavbarPresentations", route: "/informs/present" },
      { title: "message.NavbarAboutUs", route: "/company/aboutus" },
      { title: "message.NavbarAsk", route: "/informs/ask" },
    ],
    dialog: false,
    attrs: false,
    on: false,
    toggleSelect: false,
    password: "",
    // Lang
    // fallbackLocale: "ru",
    locale: window.navigator.language.slice(0, 2),
    langs: ["ru", "gb", "de"],
    flags: ["ru", "gb", "de"],
    textblock: [],
    pictureblocks: [],
  }),
  components: {},
  computed: {
    layout() {
      console.log();
      return (this.$route.meta.layout || "empty") + "-layout";
    },
  },

  methods: {
    scrollingn() {

      window.addEventListener("scroll", callbackFunc);
      function callbackFunc() {
        var nbarcard = document.querySelector("#nbarcard");
        var y = window.pageYOffset;
        if (y > 50) {
          nbarcard.classList.add("scrolln");
        } else {
          nbarcard.classList.remove("scrolln");
        }
      }
    },
  },
  async mounted() {
    // this.load();
    if (this.locale !== "ru" && localStorage.locale == undefined) {
      this.$i18n.locale = "gb";
      this.locale = "gb";
      await this.$store.dispatch("getTextblock");
    } else if (this.locale == "ru" && localStorage.locale == undefined) {
      this.$i18n.locale = "ru";
      this.locale = "ru";
      await this.$store.dispatch("getTextblock");
    } else {
      this.$i18n.locale = localStorage.locale;
      this.locale = localStorage.locale;
    }
    // await this.$store.dispatch("getTextblock");
    //
    this.textblock = await this.$store.getters.getTextblock;
    this.$i18n.setLocaleMessage(this.locale, this.textblock[this.locale]);
    console.log(this.$i18n.locale);
    if (localStorage.locale) {
      this.locale = localStorage.locale;
    }
  },
  watch: {
    locale: function (newValue) {
      if (newValue) {
        this.$i18n.locale = newValue;
        this.$i18n.setLocaleMessage(newValue, this.textblock[newValue]);
        localStorage.locale = newValue;
      }
    },
  },
};
</script>

<style>
.scrolln {
  margin-top: 0px !important;
  min-height: 70px !important;
  z-index: 102 !important; 
  background-color: none !important;
  border-color: white !important;
  background: none !important;
  transition: 1s;
}

#nbarcard {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  max-height: 80px;
  min-width: 100%;
  overflow: visible;
  position: fixed;
  z-index: 100;
  text-align: left;
  padding-left: 22%;
  padding-right: 22%;
  margin-top: 70px;
  background: url("../assets/images/headbg3.jpg");
  transition: 1s;
}
#navbar-link {
  position: absolute;
  margin-left: 90%;
  text-decoration: none;
}
#loginbtn {
  height: 45px;
  width: 65px;
  opacity: 0;
}
#loginbtn:hover {
  opacity: 0.8;
}
#navbar-lang {
  position: absolute;
  height: 45px;
  width: 65px;
  margin-right: 90%;
}
#selected {
  width: 10px;
  height: 10px;
}
#navmenubtn {
  height: 48px;
  font-size: 13px;
  background-color: white;
  font-weight: 700;
}
@media (max-width: 1140px) {
.scrolln {
  opacity: 0 !important;
  
}
}

@media (max-width: 970px) {
  #nbarcard {
    flex-wrap: wrap;
    padding-left: 0%;
    padding-right: 0%;
    /* margin-top: 10px; */
    min-height: 60px;
    max-height: 80px;
    justify-content: center;
  }
}
@media (max-width: 720px) {
  #nbarcard {
    flex-wrap: wrap;
    padding-left: 0%;
    padding-right: 0%;

    min-height: 60px;
    max-height: 140px;
    justify-content: center;
    
  }
}
@media (max-width: 782px) {
  #navbar-link {
    margin-top: 100px;
  }
  #navbar-lang {
    margin-top: 100px;
  }
}
@media (max-width: 632px) {
  #navbar-link {
    margin-top: 150px;
    margin-left: 85%;
  }
  #navbar-lang {
    margin-top: 150px;
    margin-right: 85%;
  }
}
@media (max-width: 368px) {
  #navbar-link {
    margin-top: 170px;
    margin-left: 80%;
  }
  #navbar-lang {
    margin-top: 170px;
    margin-right: 80%;
  }
  #nbarcard {
    min-height: 60px;
    max-height: 150px;
  }
}
</style>