export default {
    actions: {
        async getConfig(ctx) {
            const axios = require("axios").default;
            let configs = null;
            let getConfig = axios({
                url: "../../config.json",
            }).then((respose) => {
                configs = respose.data;
                return configs;
            });

            const config = await getConfig;
            let url = "";
            for (let i = 0; i < config.length; i++) {
                if (config[i].name === "api") {
                    url = config[i].ip;
                }
            }
            // state.config = url;
            // console.log (state.config)
            
            ctx.commit ("updateConfig", url)   
            return url;      
        }
    },
    mutations: {
        updateConfig(state, config){
            state.config = config;
        }
    },
    state: {
        config: '',
    },
    getters: {
        getConfig(state) {
            return state.config;
        },
    }
}