export default {
    actions: {
        async getAllArticleblocksNoFile(ctx) {
            const axios = require("axios").default;
            let configs = null;
            let getConfig = axios({
                url: "../../config.json",
            }).then((respose) => {
                configs = respose.data;
                return configs;
            });

            const config = await getConfig;
            let url = "";

            for (let i = 0; i < config.length; i++) {
                if (config[i].name === "api") {
                    url = config[i].ip + "/GetAllArticleblocksNoFile";
                }
            }
            const getArticleblocksNoFileAxios = async (url, ctx) => {
                let count = 0;
                await axios.get(url)
                    .then((response) => {

                        //     console.log(response);
                        //     console.log(ctx);
                        if (response.status == "200") {
                            // console.log(response);
                            ctx.commit("updateAllArticleblocksNoFile", response.data);
                            // ctx.commit("loadp", response.data);
                        }
                    })
                    .catch(async (error) => {
                        console.log(error);
                        if (count > 3) {
                            await getArticleblocksNoFileAxios(url);
                        }
                        count++;
                    });
            }

            await getArticleblocksNoFileAxios(url, ctx);
        },
        async getAllArticleblocksOnlyFile(ctx) {
            const axios = require("axios").default;
            let configs = null;
            let getConfig = axios({
                url: "../../config.json",
            }).then((respose) => {
                configs = respose.data;
                return configs;
            });

            const config = await getConfig;
            let url = "";

            for (let i = 0; i < config.length; i++) {
                if (config[i].name === "api") {
                    url = config[i].ip + "/GetAllArticleblocksOnlyFile";
                }
            }
            const getArticleblocksOnlyFileAxios = async (url, ctx) => {
                let count = 0;
                await axios.get(url)
                    .then((response) => {

                        //     console.log(response);
                        //     console.log(ctx);
                        if (response.status == "200") {
                            // console.log(response);
                            ctx.commit("updateAllArticleblocksOnlyFile", response.data);
                            // ctx.commit("loadp", response.data);
                        }
                    })
                    .catch(async (error) => {
                        console.log(error);
                        if (count > 3) {
                            await getArticleblocksOnlyFileAxios(url);
                        }
                        count++;
                    });
            }

            await getArticleblocksOnlyFileAxios(url, ctx);
        }
    },
    mutations: {
        updateAllArticleblocksNoFile(state, articleblocks) {
            state.articleblocks = articleblocks;
        },  
        updateAllArticleblocksOnlyFile(state, articleblocks) {
            state.articleblocks = articleblocks;
        },        

    },
    state: {
        articleblocks: [],
        
    },
    getters: {
        getArticleblocksNoFile(state) {
            return state.articleblocks;
        },
    }

}