<template>
  <!-- Преимущества -->
  <div id="benefits">
    <div id="bene-block">
      <img :src="getImageUrlbyName('Ben1')" />
      <div>&nbsp;</div>
      <div>{{ $t("message.Benefits1") }}</div>
    </div>
    <div id="bene-block">
      <img :src="getImageUrlbyName('Ben2')" />
      <div>&nbsp;</div>
      <div>{{ $t("message.Benefits2") }}</div>
    </div>
    <div id="bene-block">
      <img :src="getImageUrlbyName('Ben3')" />
      <div>&nbsp;</div>
      <div>{{ $t("message.Benefits3") }}</div>
    </div>
    <div id="bene-block">
      <img :src="getImageUrlbyName('Ben4')" />
      <div>&nbsp;</div>
      <div>{{ $t("message.Benefits4") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {},
  methods: {},
};
</script>

<style>
/* Преимущества */

#benefits {
  background-color: rgb(245, 245, 245);
  min-height: 100px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-left: 25%;
  padding-right: 25%;
  align-items: center;
  font-size: 14px;
}
#bene-block {
  display: flex;
  align-items: center;
}
@media (max-width: 1220px) {
  #benefits {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media (max-width: 600px) {
  #benefits {
    display: flex;
    flex-wrap: wrap;
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 20px;
    justify-content: left;
  }
  #bene-block {
    padding-bottom: 20px;
  }
}
</style>