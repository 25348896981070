
export default {
  actions: {
    async getTextblock(ctx) {
      const axios = require("axios").default;
      let configs = null;

      let getConfig = axios({
        url: "../../config.json",

      }).then((respose) => {
        configs = respose.data;
        console.log("Загрузили настройки");
        return configs;
      });

      const config = await getConfig;
      let url = "";

      for (let i = 0; i < config.length; i++) {
        if (config[i].name === "api") {
          url = config[i].ip + "/GetAllTextblocks";

        }
      }
      const getTextblockAxios = async (url, ctx) => {
        // let count = 0;
        let textblock = await axios.get(url);
        if (textblock?.data) {
          ctx.commit("updateTextblock", textblock.data);
          ctx.commit("load", textblock.data);
        }
        // .then((response) => {
        //   if (response.statusText === "OK") {
        //     console.log(response);
        //     ctx.commit("updateLabels", response.data);
        //   }
        // })
        // .catch( async (error) =>{
        //   console.log(error);
        //   if(count > 3){
        //     await getLabelsAxios(url);
        //   }
        //   count++;
        // });
      }
      await getTextblockAxios(url, ctx);
    },
    async getLang(ctx, local) {
      const axios = require("axios").default;
      let configs = null;
      let getConfig = axios({
        url: "./config.json",
      }).then((respose) => {
        configs = respose.data;
        // console.log("Загрузили настройки");
        return configs;
      });

      const config = await getConfig;
      // console.log(config);
      let url = "";

      for (let i = 0; i < config.length; i++) {
        if (config[i].name === "api") {
          url = config[i].ip + "/GetLang/" + local;
        }
      }
      // console.log(url);
      await axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          // console.log("запрос получен");
          //   console.log(response);
          if (response.statusText === "OK") {
            ctx.commit("updateLang", response.data);
          }
        })
        .catch(function (error) {
          if (error.toString().indexOf("Network Error")) {
            alert("Network error, failure get local");
          } else {
            alert("Unknown error");
          }
        });
    },
  },
  mutations: {
    updateTextblock(state, textblock) {
      // let a = JSON.stringify(labels);
      // console.log(JSON.parse(a));
      let parseObject = {
        ru: {
          message: {},
        },
        gb: {
          message: {},
        },
        de: {
          message: {},
        },
      };
      for (let i = 0; i < textblock.length; i++) {
        if (!parseObject.ru.hasOwnProperty.call(textblock[i].name)) {
          parseObject.ru.message[textblock[i].name] = textblock[i].value1;
        }
        if (!parseObject.gb.hasOwnProperty.call(textblock[i].name)) {
          parseObject.gb.message[textblock[i].name] = textblock[i].value;
        }
        if (!parseObject.de.hasOwnProperty.call(textblock[i].name)) {
          parseObject.de.message[textblock[i].name] = textblock[i].value2;
        }
      }
      state.textblock = parseObject;
    },
    updateLang(state, local) {
      state.local = { message: local };
    },
    load(state, textblock) {
      if (textblock !== undefined) {
        state.messageLoading = true
      }
      else if (textblock !== undefined) {
        state.messageLoading = true
      }
    },
  },
  state: {
    textblock: {},
    local: {},
    messageLoading: false,
  },
  getters: {
    getTextblock(state) {
      return state.textblock;
    },
    getLang(state) {
      return state.local;
    },
  },
};