<template>
  <!-- Услуги -->
  <div id="services" class="chapter">
    <h1 id="naming">{{ $t("message.AdditionalServices") }}</h1>
    <div id="servtext">
      {{ $t("message.ServText") }}
    </div>
    <div id="service-items">
      <v-card max-width="300" id="serv-card" :loading="loading">
        <router-link to="/products/integration">
          <img :src="getImageUrlbyName('Serv1')" href="" />
          <div id="cardtitle">{{ $t("message.NavbarIntegration") }}</div>
        </router-link>
        <div id="cardtext">
          {{ $t("message.ServIntegration") }}
        </div>
      </v-card>
      <v-card max-width="300" id="serv-card" :loading="loading">
        <router-link to="/products/refact">
          <img :src="getImageUrlbyName('Serv2')" href="" />
          <div id="cardtitle">{{ $t("message.NavbarRefact") }}</div>
        </router-link>
        <div id="cardtext">
          {{ $t("message.ServRefact") }}
        </div>
      </v-card>
      <v-card max-width="300" id="serv-card" :loading="loading">
        <router-link to="/products/audit">
          <img :src="getImageUrlbyName('Serv3')" href="" />
          <div id="cardtitle">{{ $t("message.NavbarAudit") }}</div>
        </router-link>
        <div id="cardtext">
          {{ $t("message.ServAudit") }}
        </div>
      </v-card>
      <v-card max-width="300" id="serv-card" :loading="loading">
        <router-link to="/products/delivery">
          <img :src="getImageUrlbyName('Serv4')" href="" />
          <div id="cardtitle">{{ $t("message.NavbarDelivery") }}</div>
        </router-link>
        <div id="cardtext">
          {{ $t("message.ServDelivery") }}
        </div>
      </v-card>
      <v-card max-width="300" id="serv-card" :loading="loading">
        <router-link to="/products/benztruck">
          <img :src="getImageUrlbyName('Serv5')" href="" />
          <div id="cardtitle">{{ $t("message.NavbarBenztruck") }}</div>
        </router-link>
        <div id="cardtext">
          {{ $t("message.ServBenztruck") }}
        </div>
      </v-card>
      <v-card max-width="300" id="serv-card" :loading="loading">
        <router-link to="/products/merch">
          <img :src="getImageUrlbyName('Serv6')" href="" />
          <div id="cardtitle">{{ $t("message.NavbarMerch") }}</div>
        </router-link>
        <div id="cardtext">
          {{ $t("message.ServMerch") }}
        </div>
      </v-card>
      <v-card max-width="300" id="serv-card" :loading="loading">
        <router-link to="/products/trash">
          <img :src="getImageUrlbyName('Serv7')" href="" />
          <div id="cardtitle">{{ $t("message.NavbarTrash") }}</div>
        </router-link>
        <div id="cardtext">
          {{ $t("message.ServTrash") }}
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  computed: mapState({
    loading: (state) => state.loader.loading,
  }),
  methods: mapMutations(["reserve"]),
  async mounted() {
    this.reserve();
  },
};
</script>

<style>
/* Дополнительные услуги */

.chapter {
  height: 100%;
  width: 100%;
  padding-left: 25%;
  padding-right: 25%;
  padding-top: 40px;
}
.chapter-no-line {
  height: 100%;
  width: 100%;
  padding-left: 25%;
  padding-right: 25%;
  padding-top: 40px;
}
.chapter::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  margin-top: 10px;
  background-color: #d6d6d6;
}
#naming {
  font-size: 24px;
  letter-spacing: normal;
  display: inline;
  border-bottom: 2px solid #00569c;
}

/* #naming::after {
  content: "";
  display: block;
  min-width: 10px;
  max-width: 300px;
  height: 2px;
  margin-top: 10px;
  background-color: #00569c;
} */

#servtext {
  font-size: 13px;
  padding-top: 20px;
  
}

#service-items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#serv-card {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 8px;
}
#cardtitle {
  font-size: 15px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 8px;
  text-decoration: none;
  color: #00569c;
}
#cardtitle:hover {
  text-decoration: none;
  color: black;
}
#cardtext {
  font-size: 14px;
  margin-left: 8px;
  color: #555;
}

@media (max-width: 1220px) {
  .chapter {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 40px;
  }
  .chapter-no-line {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 40px;
  }

  #serv-card {
    display: flex;
    width: 200px;
  }
  #service-items {
    justify-content: center;
  }
}
</style>