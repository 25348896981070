<template >
  <div id="home">
    <Header />
    <Navbar />
    <div v-if="!picLoading" class="loader" style="--b: 10px;--c:#00569c;width:80px;--n:15;--g:7deg"></div>
    <transition name ="fade">
    <body id="body" v-if="picLoading">
      <Slider />
      <Benefits />
      <Services />
      <Videoblock />
      <Prodcards />
      <Consult />
      <CompanyNews />
      <Partners />
    </body>
    </transition>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Slider from "../components/Slider";
import Benefits from "../components/Benefits";
import Services from "../components/Services";
import Videoblock from "../components/Videoblock";
import Prodcards from "../components/Prodcards";
import Consult from "../components/Consult";
import CompanyNews from "../components/CompanyNews";
import Partners from "../components/Partners";
import Footer from "../components/Footer.vue";


import { mapState, mapMutations } from "vuex";
export default {
  name: "home",
  components: {
    Header,
    Navbar,
    Slider,
    Benefits,
    Services,
    Videoblock,
    Prodcards,
    Consult,
    CompanyNews,
    Partners,
    Footer, //
  },

  title() {
    if (this.$i18n.locale === "ru") {
        return `${this.ruValue}`;
      } else if (this.$i18n.locale === "gb") {
         return `${this.gbValue}`;
      } else if (this.$i18n.locale === "de") {
         return `${this.deValue}`;
      }
  },
  data() {
    return {
      ruValue: 'ИТС — интеллектуальные системы - its.ooo',
      gbValue: 'ITS - intelligent systems - its.ooo',
      deValue: 'ITS - intelligente Systeme - its.ooo',
    };
  },
  // async created() {
  //   // this.$store.dispatch("getTextblock");
  //   // var a = this.store.getters.getTextblock;
  //   // console.log(a)
  //   // Vue.prototype.$messages = this.$store.getters.getTextblock;
  // },
  methods: mapMutations(["load","loadp"],{
  }),

  computed: mapState({
    picLoading: (state) => state.pictureblocks.picLoading,
    layout() {
      console.log();
      return (this.$route.meta.layout || "empty") + "-layout";
    },
  }),
};
</script>

<style>
#home {
  background-color: #ffffff;
  align-items: center;
}
#body {
  font-family: Ubuntu, Arial, sans-serif;
}
@media (min-width: 2560px) {
  #home {
    margin-left: 10%;
    margin-right: 10%;
  }
}
.fade-enter-active,
.fade-leave-active {
  animation: appear 2s;
}
@keyframes appear {
  0% {opacity: 0}
  100% {opacity: 1}
}
.loader {
  --b: 10px;  /* border thickness */
  --n: 10;    /* number of dashes*/
  --g: 10deg; /* gap  between dashes*/
  --c: red;   /* the color */
  margin-top: 15%;
  margin-bottom: 5%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width:100px; /* size */
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px; /* get rid of bad outlines */
  background: conic-gradient(#0000,var(--c)) content-box;
  --_m: /* we use +/-1deg between colors to avoid jagged edges */
    repeating-conic-gradient(#0000 0deg,
       #000 1deg calc(360deg/var(--n) - var(--g) - 1deg),
       #0000     calc(360deg/var(--n) - var(--g)) calc(360deg/var(--n))),
    radial-gradient(farthest-side,#0000 calc(98% - var(--b)),#000 calc(100% - var(--b)));
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: destination-in;
          mask-composite: intersect;
  animation: load 1s infinite steps(var(--n));
}
@keyframes load {to{transform: rotate(1turn)}}
@media (max-width: 960px) {
  .loader {
    margin-top: 200px;
  }
}
@media (max-width: 360px) {
  .loader {
    margin-top: 250px;
  }
}
</style>
