<template>
  <div class="EmptyLayout" id = "ey">
    <Header />
    <Navbar />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "../components/Header";
import Navbar from "../components/Navbar";

export default {
  name: "main",
  data: () => ({}),
  components: {
    Header,
    Navbar,
    },
}
</script>

<style>

/* #ey {
  background: url("../assets/images/headbg.jpg") 
} */

</style>