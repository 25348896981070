
<template>
  <div class="hcont">
    {{ scrolling() }}
    <div class="header">
      <img
        id="logo1"
        src="../assets/images/logoblue4.png"
        
        alt=""
        width="145"
        height="50"
        @click="$router.push('/')"
      />
      <!-- getImageUrlbyName('Logo') -->
      <div id="headertext" href="">
        {{ $t("message.HeaderText") }}
      </div>
      <div class="phone">
        <div class="phone-number">
          <v-icon color="white">mdi-phone</v-icon>
          <!-- <img src="../assets/images/phone.jpg" height="25" width="25" /> -->
          <div>+7 (846) 219-26-28</div>
        </div>
        <div id="phone-desc">
          <v-icon small color="white">mdi-email</v-icon>
          <div id="mail">info@its.ooo</div>
        </div>
      </div>
      <v-dialog v-model="dialog" width="500" id="fb-bg">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            color="primary"
            elevation="2"
            outlined
            id="callback"
            >{{ $t("message.RequestACall") }}</v-btn
          >
        </template>
        <v-card min-height="500">
          <div id="feedback-marg">
            <Feedback />
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Feedback from "../components/Feedback";
export default {
  components: {
    Feedback,
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    scrolling() {
      
      window.addEventListener("scroll", callbackFunc);
      function callbackFunc() {
        var hcont = document.querySelector(".hcont");
        var y = window.pageYOffset;
        if (y > 50) {
          hcont.classList.add("scroll");
          
        } else {
          hcont.classList.remove("scroll");
        }
      }
    },
  },
};
</script>


<style>
/* Хедер */
.hcont {
  width: 100%;
  z-index: 101;
  position: fixed;
  background: url("../assets/images/bghead1.jpg");
}
.hcont img {
  transition: 1s;
}

.scroll {
  opacity: 0.9;
  background: none;
  background-color: #152863;
  
  transition: background-color 1s;
}
.scroll img {
  width: 130px;
  height: 45px;
  transition: 1s;
}
.scroll .header {
  border-bottom: none;
}
.scroll #headertext {
  opacity: 0;
  transition: 1s;
}
.scroll .phone {
  opacity: 0;
  transition: 1s;
}
.header {
  box-sizing: border-box;
  width: 70%;
  /* border: solid #5B6DCD 10px; */

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  margin-left: 15%;
  margin-right: 15%;
  overflow: visible;

  border-bottom: 1px solid #e3e7ec;
  text-align: left;
  font: 16px/1.5 "Open Sans", sans-serif;
}
#phone {
  height: 70px;
  word-wrap: break-word;
}
#headertext {
  margin: 10%;
  max-width: 300px;
  font: 13px "Ubuntu", Arial, sans-serif;
  line-height: 1.428571429;
  /* color: #95979a; */
  color: #ffffff;
}

.phone-number {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
}

#callback {
  height: 30px;
  width: 120px;
  margin-left: 30px;
  font-size: 10px;
  font-family: Ubuntu, Arial, sans-serif;
  background-color: #ffffff;
  color: #00569c;
  letter-spacing: 0.01em;
}
/* 
.phone-icon {	
  
} */

#phone-desc {
  display: flex;
  font-family: Ubuntu, Arial, sans-serif;
  font-size: 11px;
  text-align: right;
  color: #ffffff;
}
@media (max-width: 1460px) {
  .header {
    margin-left: 0%;
    margin-right: 0%;
    width: 100%;
  }
}
@media (max-width: 1140px) {
  #headertext {
    display: none;
  }
  .header {
    justify-content: space-around;
    height: 70px;
  }
  .scroll .phone {
  opacity: 1;
  transition: 1s;
  }
}

@media (max-width: 530px) {
  #headertext {
    display: none;
  }
  #callback {
    display: none;
  }
  .header {
    justify-content: space-around;
    height: 70px;
  }
  #logo1 {
    width: 110px;
    height: 40px;
  }
}
</style>
