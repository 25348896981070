
export default [
    {
        path: '/products/struck',
        name: 'STruck',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Products/STruck')
      },
      {
        path: '/products/steams',
        name: 'STeams',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Products/STeams')
      },
      {
        path: '/products/sprod',
        name: 'SProd',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Products/SProd')
      },
      {
        path: '/products/integration',
        name: 'Integration',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Products/Integration')
      },
      {
        path: '/products/advserv',
        name: 'Advserv',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Products/Advserv')
      },
      {
        path: '/products/audit',
        name: 'Audit',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Products/Audit')
      },
      {
        path: '/products/benztruck',
        name: 'BenzTruck',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Products/BenzTruck')
      },
      {
        path: '/products/delivery',
        name: 'Deliver',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Products/Deliver')
      },
      {
        path: '/products/merch',
        name: 'Merch',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Products/Merch')
      },
      {
        path: '/products/refact',
        name: 'Refact',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Products/Refact')
      },
      {
        path: '/products/trash',
        name: 'Trash',
        meta: {layout: 'empty'},
        component: () => import( '../../views/Navigations/Products/Trash')
      },
]